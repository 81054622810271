<template>
	<div class="Security">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_安全设置</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->
		<div class="table1">
			<div class="flex">
				<div class="title">
					<div class="title_context">安<br/>全<br/>限<br/>幅</div>
				</div>
				<div>
					<!-- 右边上部分 -->
					<div class="table1_line1 flex">
						<!-- 第一列 -->
						<div class="table1_context">
							<div class="title1">过热器</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">主给水泵调节上限</div>
									<div class="column2 float1"
										@click="toIpt(infoList.MAN5.OUTT, '主给水泵调节上限','OUTT',infoList.MAN5_node_string)">
											{{'MAN5' in infoList?infoList.MAN5.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">主给水泵调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN5.OUTB, '主给水泵调节下限','OUTB',infoList.MAN5_node_string)">
											{{'MAN5' in infoList?infoList.MAN5.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">副给水泵调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN6.OUTT, '副给水泵调节上限','OUTT',infoList.MAN6_node_string)">
											{{'MAN6' in infoList?infoList.MAN6.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">副给水泵调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN6.OUTB, '副给水泵调节下限','OUTB',infoList.MAN6_node_string)">
											{{'MAN6' in infoList?infoList.MAN6.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN7.OUTT, '1#减温阀门上限','OUTT',infoList.MAN7_node_string)">
											{{'MAN7' in infoList?infoList.MAN7.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN7.OUTB, '1#减温阀门下限','OUTB',infoList.MAN7_node_string)">
											{{'MAN7' in infoList?infoList.MAN7.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN8.OUTT, '2#减温阀门上限','OUTT',infoList.MAN8_node_string)">
											{{'MAN8' in infoList?infoList.MAN8.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN8.OUTB, '2#减温阀门下限','OUTB',infoList.MAN8_node_string)">
											{{'MAN8' in infoList?infoList.MAN8.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN9.OUTT, '3#减温阀门上限','OUTT',infoList.MAN9_node_string)">
											{{'MAN9' in infoList?infoList.MAN9.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN9.OUTB, '3#减温阀门下限','OUTB',infoList.MAN9_node_string)">
											{{'MAN9' in infoList?infoList.MAN9.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN10.OUTT, '4#减温阀门上限','OUTT',infoList.MAN10_node_string)">
											{{'MAN10' in infoList?infoList.MAN10.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN10.OUTB, '4#减温阀门下限','OUTB',infoList.MAN10_node_string)">
											{{'MAN10' in infoList?infoList.MAN10.OUTB :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第二列 -->
						<div class="table1_context">
							<div class="title1">给煤限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">1#给煤调节上限</div>
									<div class="column2 float1"
										@click="toIpt(infoList.MAN1.OUTT, '1#给煤调节上限','OUTT',infoList.MAN1_node_string)">
											{{'MAN1' in infoList?infoList.MAN1.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN1.OUTB, '1#给煤调节下限','OUTB',infoList.MAN1_node_string)">
											{{'MAN1' in infoList?infoList.MAN1.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN2.OUTT, '2#给煤调节上限','OUTT',infoList.MAN2_node_string)">
											{{'MAN2' in infoList?infoList.MAN2.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN2.OUTB, '2#给煤调节下限','OUTB',infoList.MAN2_node_string)">
											{{'MAN2' in infoList?infoList.MAN2.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN3.OUTT, '3#给煤调节上限','OUTT',infoList.MAN3_node_string)">
											{{'MAN3' in infoList?infoList.MAN3.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN3.OUTB, '3#给煤调节下限','OUTB',infoList.MAN3_node_string)">
											{{'MAN3' in infoList?infoList.MAN3.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN4.OUTT, '4#给煤调节上限','OUTT',infoList.MAN4_node_string)">
											{{'MAN4' in infoList?infoList.MAN4.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN4.OUTB, '4#给煤调节下限','OUTB',infoList.MAN4_node_string)">
											{{'MAN4' in infoList?infoList.MAN4.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">5#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN5.OUTT, '5#给煤调节上限','OUTT',infoList.MAN5_node_string)">
											{{'MAN5' in infoList?infoList.MAN5.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">5#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN5.OUTB, '5#给煤调节下限','OUTB',infoList.MAN5_node_string)">
											{{'MAN5' in infoList?infoList.MAN5.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">6#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN6.OUTT, '6#给煤调节上限','OUTT',infoList.MAN6_node_string)">
											{{'MAN6' in infoList?infoList.MAN6.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">6#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MAN6.OUTB, '6#给煤调节下限','OUTB',infoList.MAN6_node_string)">
											{{'MAN6' in infoList?infoList.MAN6.OUTB :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第三列 -->
						<div class="table1_context">
							<div class="title1">引风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">引风机电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.BYMNL41,'引风机电流上限','BYMNL41',infoList.BYMNL41_node_string)">
									    {{'BYMNL41' in infoList?infoList.BYMNL41 :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN19.OUTT,'1#引风变频上限','OUTT',infoList.MAN19_node_string)">
									    {{'MAN19' in infoList?infoList.MAN19.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN19.OUTB,'1#引风变频下限','OUTB',infoList.MAN19_node_string)">
									    {{'MAN19' in infoList?infoList.MAN19.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN20.OUTT,'2#引风变频上限','OUTT',infoList.MAN20_node_string)">
									    {{'MAN20' in infoList?infoList.MAN20.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN20.OUTB,'2#引风变频下限','OUTB',infoList.MAN20_node_string)">
									    {{'MAN20' in infoList?infoList.MAN20.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN21.OUTT,'1#引风挡板上限','OUTT',infoList.MAN21_node_string)">
									    {{'MAN21' in infoList?infoList.MAN21.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN21.OUTB,'1#引风挡板下限','OUTB',infoList.MAN21_node_string)">
									    {{'MAN21' in infoList?infoList.MAN21.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN22.OUTT,'2#引风挡板上限','OUTT',infoList.MAN22_node_string)">
									    {{'MAN22' in infoList?infoList.MAN22.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN22.OUTB,'2#引风挡板下限','OUTB',infoList.MAN22_node_string)">
									    {{'MAN22' in infoList?infoList.MAN22.OUTB :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第四列 -->
						<div class="table1_context">
							<div class="title1">一次风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">一次风电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.BYMNL42,'一次风电流上限','BYMNL42',infoList.BYMNL42_node_string)">
									    {{'BYMNL42' in infoList?infoList.BYMNL42 :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#一次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN16.OUTT,'1#一次风挡板上限','OUTT',infoList.MAN16_node_string)">
									    {{'MAN16' in infoList?infoList.MAN16.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#一次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN16.OUTB,'1#一次风挡板下限','OUTB',infoList.MAN16_node_string)">
									    {{'MAN16' in infoList?infoList.MAN16.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#一次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN25.OUTT,'2#一次风挡板上限','OUTT',infoList.MAN25_node_string)">
									    {{'MAN25' in infoList?infoList.MAN25.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#一次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN25.OUTB,'2#一次风挡板下限','OUTB',infoList.MAN25_node_string)">
									    {{'MAN25' in infoList?infoList.MAN25.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">一次风上限</div>
									<div class="column2"
									@click="toIpt(infoList.YCFSPH,'一次风上限','YCFSPH',infoList.YCFSPH_node_string)">
									    {{'YCFSPH' in infoList?infoList.YCFSPH :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">一次风下限</div>
									<div class="column2"
									@click="toIpt(infoList.YCFSPL,'一次风下限','YCFSPL',infoList.YCFSPL_node_string)">
									    {{'YCFSPL' in infoList?infoList.YCFSPL :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第五列 -->
						<div class="table1_context">
							<div class="title1">二次风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">二次风电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.BYMNL43,'二次风电流上限','BYMNL43',infoList.BYMNL43_node_string)">
									    {{'BYMNL43' in infoList?infoList.BYMNL43 :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN17.OUTT,'1#二次风变频上限','OUTT',infoList.MAN17_node_string)">
									    {{'MAN17' in infoList?infoList.MAN17.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN17.OUTB,'1#二次风变频下限','OUTB',infoList.MAN17_node_string)">
									    {{'MAN17' in infoList?infoList.MAN17.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN30.OUTT,'2#二次风变频上限','OUTT',infoList.MAN30_node_string)">
									    {{'MAN30' in infoList?infoList.MAN30.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN30.OUTB,'2#二次风变频下限','OUTB',infoList.MAN30_node_string)">
									    {{'MAN30' in infoList?infoList.MAN30.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN18.OUTT,'1#二次风挡板上限','OUTT',infoList.MAN18_node_string)">
									    {{'MAN18' in infoList?infoList.MAN18.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN18.OUTB,'1#二次风挡板下限','OUTB',infoList.MAN18_node_string)">
									    {{'MAN18' in infoList?infoList.MAN18.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN26.OUTT,'2#二次风挡板上限','OUTT',infoList.MAN26_node_string)">
									    {{'MAN26' in infoList?infoList.MAN26.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN26.OUTB,'2#二次风挡板下限','OUTB',infoList.MAN26_node_string)">
									    {{'MAN26' in infoList?infoList.MAN26.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">二次风上限</div>
									<div class="column2"
									@click="toIpt(infoList.ECFSPH,'二次风上限','ECFSPH',infoList.ECFSPH_node_string)">
									    {{'ECFSPH' in infoList?infoList.ECFSPH :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">二次风下限</div>
									<div class="column2"
									@click="toIpt(infoList.ECFSPL,'二次风下限','ECFSPL',infoList.ECFSPL_node_string)">
									    {{'ECFSPL' in infoList?infoList.ECFSPL :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第六列 -->
						<div class="table1_context">
							<div class="title1">冷渣机限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">1#冷渣机调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MAN13.OUTT,'1#冷渣机调节上限','OUTT',infoList.MAN13_node_string)">
									    {{'MAN13' in infoList?infoList.MAN13.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN13.OUTB,'1#冷渣机调节下限','OUTB',infoList.MAN13_node_string)">
									    {{'MAN13' in infoList?infoList.MAN13.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN14.OUTT,'1#冷渣机调节上限','OUTT',infoList.MAN14_node_string)">
									    {{'MAN14' in infoList?infoList.MAN14.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN14.OUTB,'1#冷渣机调节下限','OUTB',infoList.MAN14_node_string)">
									    {{'MAN14' in infoList?infoList.MAN14.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN28.OUTT,'3#冷渣机调节上限','OUTT',infoList.MAN28_node_string)">
									    {{'MAN28' in infoList?infoList.MAN28.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN28.OUTB,'3#冷渣机调节下限','OUTB',infoList.MAN28_node_string)">
									    {{'MAN28' in infoList?infoList.MAN28.OUTB :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN29.OUTT,'4#冷渣机调节上限','OUTT',infoList.MAN29_node_string)">
									    {{'MAN29' in infoList?infoList.MAN29.OUTT :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN29.OUTB,'4#冷渣机调节下限','OUTB',infoList.MAN29_node_string)">
									    {{'MAN29' in infoList?infoList.MAN29.OUTB :0}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 右边下部分 -->
					<div class="table1_line2 flex">
						<!-- 第一个表 -->
						<div class="form1 flex">
							<div>
								<div class="flex">
									<div class="column1 float1">负荷范围上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.FHSPHH,'负荷范围上限','FHSPHH',infoList.FHSPHH_node_string)">
									    {{'FHSPHH' in infoList?infoList.FHSPHH :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">负荷范围下限</div>
									<div class="column2"
									@click="toIpt(infoList.FHSPLL,'负荷范围上限','FHSPLL',infoList.FHSPLL_node_string)">
									    {{'FHSPLL' in infoList?infoList.FHSPLL :0}}
									</div>
								</div>
							</div>
							<div class="line2">
								<div class="flex">
									<div class="column1 float2">给煤量上线系数</div>
									<div class="column2 float2"
									@click="toIpt(infoList.BYMNL11,'给煤量上线系数','BYMNL11',infoList.BYMNL11_node_string)">
									    {{'BYMNL11' in infoList?infoList.BYMNL11 :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">给煤量上限</div>
									<div class="column2"
									@click="toIpt(infoList.GMLH,'给煤量上限','GMLH',infoList.GMLH_node_string)">
									    {{'GMLH' in infoList?infoList.GMLH :0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">给煤量下限</div>
									<div class="column2"
									@click="toIpt(infoList.GMLL,'给煤量下限','GMLL',infoList.GMLL_node_string)">
									    {{'GMLL' in infoList?infoList.GMLL :0}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第二个表 -->
						<div class="form2 flex">
							<div class="form2_word">再热器</div>
							<div>
								<div class="flex">
									<div class="column1 float1">1#减温调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MAN53.OUTB,'1#减温调节上限','OUTB',infoList.MAN53_node_string)">
									   {{'MAN53' in infoList? infoList.MAN53.OUTB : "0.00" }}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN53.OUTT,'1#减温调节下限','OUTT',infoList.MAN53_node_string)">
									   {{'MAN53' in infoList? infoList.MAN53.OUTT : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN54.OUTB,'2#减温调节上限','OUTB',infoList.MAN54_node_string)">
									   {{'MAN54' in infoList? infoList.MAN54.OUTB : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN54.OUTT,'2#减温调节上限','OUTT',infoList.MAN54_node_string)">
									   {{'MAN54' in infoList? infoList.MAN54.OUTT : "0.00" }}
									</div>
								</div>
							</div>
							<div>
								<div class="flex">
									<div class="column1 float1">3#减温调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MAN55.OUTB,'3#减温调节上限','OUTB',infoList.MAN55_node_string)">
									   {{'MAN55' in infoList? infoList.MAN55.OUTB : "0.00" }}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN55.OUTT,'2#减温调节上限','OUTT',infoList.MAN55_node_string)">
									   {{'MAN55' in infoList?infoList.MAN55.OUTT : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN56.OUTB,'4#减温调节上限','OUTB',infoList.MAN56_node_string)">
									   {{'MAN56' in infoList? infoList.MAN56.OUTB : "0.00" }}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MAN56.OUTT,'2#减温调节上限','OUTT',infoList.MAN56_node_string)">
									   {{'MAN56' in infoList? infoList.MAN56.OUTT : "0.00" }}
									</div>
								</div>
							</div>
						</div>
						<div>
							<!-- 第三个表 -->
							<div class="form3 flex">
								<div class="form3_word">脱硝限幅</div>
								<div>
									<div class="flex">
										<div class="column1 float1">稀释水调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.CYSAOH,'稀释水调节上限','CYSAOH',infoList.CYSAOH_node_string)">
											    {{'CYSAOH' in infoList?infoList.CYSAOH :0}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">稀释水调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.CYSAOL,'稀释水调节下限','CYSAOL',infoList.CYSAOL_node_string)">
											    {{'CYSAOL' in infoList?infoList.CYSAOL :0}}
										</div>
									</div>
								</div>
								<div>
									<div class="flex">
										<div class="column1 float1">氨水调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.NSAOH,'氨水调节上限','NSAOH',infoList.NSAOH_node_string)">
											    {{'NSAOH' in infoList?infoList.NSAOH :0}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">氨水调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.NSAOL,'氨水调节下限','NSAOL',infoList.NSAOL_node_string)">
											    {{'NSAOL' in infoList?infoList.NSAOL :0}}
										</div>
									</div>
								</div>
							</div>
							<!-- 第四个表 -->
							<div class="form3 flex">
								<div class="form3_word">脱硫限幅</div>
								<div>
									<div class="flex">
										<div class="column1 float1">炉外脱硫调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.SO2AOH,'炉外脱硫调节上限','SO2AOH',infoList.SO2AOH_node_string)">
											    {{'SO2AOH' in infoList?infoList.SO2AOH :0}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">炉外脱硫调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.SO2AOL,'炉外脱硫调节下限','SO2AOL',infoList.SO2AOL_node_string)">
											    {{'SO2AOL' in infoList?infoList.SO2AOL :0}}
										</div>
									</div>
								</div>
								<div>
									<div class="flex">
										<div class="column1 float1">炉内脱硫调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.SO2AOH,'炉内脱硫调节上限','SO2AOH',infoList.SO2AOH_node_string)">
											    {{'SO2AOH' in infoList?infoList.SO2AOH :0}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">炉内脱硫调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.SO2AOL,'炉内脱硫调节下限','SO2AOL',infoList.SO2AOL_node_string)">
											    {{'SO2AOL' in infoList?infoList.SO2AOL :0}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
		</div>
		<!-- 中间文字 -->
		<div class="word">
			<div class="location flex">
				<div class="column1 float1">报警自控率阈值</div>
				<div class="column2 float1">0.0</div>
			</div>
		</div>
		<!-- 第二个表格 -->
		<div class="table2 flex">
			<div class="flex">
				<div class="title">
					<div class="title_context">报<br/>警<br/>设<br/>置</div>
				</div>
				<!-- 第一栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.ALMEN02, '汽压上限','ALMEN02',infoList.ALMEN02_node_string)">
							<el-switch  v-model="infoList.ALMEN02"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">汽压上限</div>
						<div class="column2"
							@click="toIpt(infoList.PT102H,'汽压上限','PT102H',infoList.PT102H_node_string)">
								{{'PT102H' in infoList?infoList.PT102H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">汽压下限</div>
						<div class="column2"
							@click="toIpt(infoList.PT102L,'汽压下限','PT102L',infoList.PT102L_node_string)">
								{{'PT102L' in infoList?infoList.PT102L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button">
							<el-switch  v-model="infoList.ALMEN11"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">优化站异常</div>
					</div>
				</div>
				<!-- 第二栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.ALMEN05,'汽温上限','ALMEN05',infoList.ALMEN05_node_string)">
							<el-switch  v-model="infoList.ALMEN05"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">汽温上限</div>
						<div class="column2"
							@click="toIpt(infoList.TE102DH,'汽温上限','TE102DH',infoList.TE102DH_node_string)">
								{{'TE102DH' in infoList?infoList.TE102DH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">汽温下限</div>
						<div class="column2"
							@click="toIpt(infoList.TE102DL,'汽温下限','TE102DL',infoList.TE102DL_node_string)">
								{{'TE102DL' in infoList?infoList.TE102DL :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.ALMEN14,'床温MAX上限','ALMEN14',infoList.ALMEN14_node_string)">
							<el-switch  v-model="infoList.ALMEN14"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">床温MAX上限</div>
						<div class="column2"
							@click="toIpt(infoList.BYMNL02,'床温MAX上限','BYMNL02',infoList.BYMNL02_node_string)">
								{{'BYMNL02' in infoList?infoList.BYMNL02 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.ALMEN14,'冷渣器水温上限','ALMEN14',infoList.ALMEN14_node_string)">
							<el-switch  v-model="infoList.ALMEN14"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">冷渣器水温上限</div>
						<div class="column2"
							@click="toIpt(infoList.LCCYL_H,'冷渣器水温上限','LCCYL_H',infoList.LCCYL_H_node_string)">
								{{'LCCYL_H' in infoList?infoList.LCCYL_H :0}}
						</div>
					</div>
				</div>
				<!-- 第三栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.ALMEN17,'氧量上限','ALMEN17',infoList.ALMEN17_node_string)">
							<el-switch  v-model="infoList.ALMEN17"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">氧量上限</div>
						<div class="column2"
							@click="toIpt(infoList.O2_ALMH,'氧量上限','O2_ALMH',infoList.O2_ALMH_node_string)">
								{{'O2_ALMH' in infoList?infoList.O2_ALMH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">氧量下限</div>
						<div class="column2"
							@click="toIpt(infoList.O2_ALML,'氧量下限','O2_ALML',infoList.O2_ALML_node_string)">
								{{'O2_ALML' in infoList?infoList.O2_ALML :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.ALMEN04,'床温均值上限','ALMEN04',infoList.ALMEN04_node_string)">
							<el-switch  v-model="infoList.ALMEN04"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">床温均值上限</div>
						<div class="column2"
							@click="toIpt(infoList.LCWD_H,'床温均值上限','LCWD_H',infoList.LCWD_H_node_string)">
								{{'LCWD_H' in infoList?infoList.LCWD_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">床温均值下限</div>
						<div class="column2"
							@click="toIpt(infoList.LCWD_L,'床温均值下限','LCWD_L',infoList.LCWD_L_node_string)">
								{{'LCWD_L' in infoList?infoList.LCWD_L :0}}
						</div>
					</div>
				</div>
				<!-- 第四栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.ALMEN03,'负压上限','ALMEN03',infoList.ALMEN03_node_string)">
							<el-switch  v-model="infoList.ALMEN03"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">负压上限</div>
						<div class="column2"
							@click="toIpt(infoList.FY_H,'负压上限','FY_H',infoList.FY_H_node_string)">
								{{'FY_H' in infoList?infoList.FY_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">负压下限</div>
						<div class="column2"
							@click="toIpt(infoList.FY_L,'负压下限','FY_L',infoList.FY_L_node_string)">
								{{'FY_L' in infoList?infoList.FY_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.ALMEN01,'水位上限','ALMEN01',infoList.ALMEN01_node_string)">
							<el-switch  v-model="infoList.ALMEN01"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">水位上限</div>
						<div class="column2"
							@click="toIpt(infoList.SW_H,'水位上限','SW_H',infoList.SW_H_node_string)">
								{{'SW_H' in infoList?infoList.SW_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">水位下限</div>
						<div class="column2"
							@click="toIpt(infoList.SW_L,'水位下限','SW_L',infoList.SW_L_node_string)">
								{{'SW_L' in infoList?infoList.SW_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">10s变化幅度</div>
						<div class="column2"
						@click="toIpt(infoList.SWBHFD,'10s变化幅度','SWBHFD',infoList.SWBHFD_node_string)">
						    {{'SWBHFD' in infoList?infoList.SWBHFD :0}}
						</div>
					</div>
				</div>
				<!-- 第五栏 -->
				<div class="table2_line2">
					<div class="first">
						<div class="flex">
							<div class="button" @click="toIpt(infoList.ALMEN06,'断煤下限','ALMEN06',infoList.ALMEN06_node_string)">
								<el-switch  v-model="infoList.ALMEN06"
								            active-color="#29DFAE"
								            inactive-color="red"
											disabled
											>
								</el-switch>
							</div>
							<div class="column1">断煤下限</div>
							<div class="column2"
								@click="toIpt(infoList.DMLL,'断煤下限','DMLL',infoList.DMLL_node_string)">
									{{'DMLL' in infoList?infoList.DMLL :0}}
							</div>
						</div>
						<div class="flex">
							<div class="button" @click="toIpt(infoList.ALMEN09,'断煤判断-10s氧量变化幅度','ALMEN09',infoList.ALMEN09_node_string)">
								<el-switch  v-model="infoList.ALMEN09"
								            active-color="#29DFAE"
								            inactive-color="red"
											disabled
											>
								</el-switch>
							</div>
							<div class="column1">断煤判断-10s氧量变化幅度</div>
							<div class="column2"
							@click="toIpt(infoList.O2BHFD,'断煤判断-10s氧量变化幅度','O2BHFD',infoList.O2BHFD_node_string)">
						    	{{'O2BHFD' in infoList?infoList.O2BHFD :0}}
							</div>
						</div>
						<div class="flex">
							<div class="button"></div>
							<div class="column1">断煤判断-10s床温变化幅度</div>
							<div class="column2"
							@click="toIpt(infoList.CWBHFD,'断煤判断-10s床温变化幅度','CWBHFD',infoList.CWBHFD_node_string)">
								{{'CWBHFD' in infoList?infoList.CWBHFD :0}}
							</div>
						</div>
					</div>
					<div class="second">
						<div class="explain_context">
							<div class="line1">注：1.报警必须“断煤处理”使能。</div>
							<div class="flex">
								<div class="blank"></div>
								<div class="line2">2.断煤时风保持当前状态。</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus = 'isClose' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
				 <Cfbcysz
      v-if="flag == 5 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbcysz>
	 <Cfbserve
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbserve>
	</div>
</template>
	
<script>	
		import inputVal from "@/components/inputVal.vue"; //输入框组件
		import Historical from "@/components/Historicaljrl.vue"  //历史趋势
		import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
		export default {
		    name: "CfbParameter3",
		    props:{
	        infoList:{
	            type:Object,
	            default: () =>{
	              return {}// 默认值
	            } 
	        }
	    },
		 components:{inputVal, Historical,Cfbserve,Cfbcysz},
		    data: () => {
		  return {
			  flag:4,
		      Manualname: "",
		      Manualnode: "",
		      ManualAname: "",
		      Manualtitname: "",
		      isComShow:false,
		      isHshow:false,
		      isMshow:false,
		      isRshow:false,
		      historyname:'',
		      node:'',
		      Lkname:'',
		      isIndex:'',
		      Firstcontrolname:'',
		      Firstcontroltitname:'',
		      Firstcontrolnode:'',
		      isFshow:false,
		      Rsfname:'',
		      Rsfnode:'',
		      Rsftitname:'',
		      projectData:'',
		      grouptime:null,
		      spotArr:[],
		      authInfo:[],
		      UserInfo:[],
		  }
		        
		 },
		   watch: {
	    infoList: {
	      handler(n, o) {
	        this.infoList = n;
			console.log(this.infoList)
	      },
	      deep: true, // 深度监听父组件传过来对象变化
	    },
	  },
		  created(){
		    this.projectData = JSON.parse(localStorage.getItem("deviceType"))
		    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		    this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			 this.$bus.$on("sendMsg", (msg) => {
						// 兄弟组件发送来的消息
						this.flag = msg;
						});
		  },
		  methods:{
		      // 打开先控多窗口
		  toDetail(key,name,nodename,Aname,titname){
		   if(!this.authInfo[1]){
		    let str = this.authInfo[0]
		    // 判断是否为调试还是查看权限
		    if (str.charAt(str.length-1) == "r") {
		     this.$message.error('暂无调试权限');
		    }
		   }else{
		    let pathname=""
		    switch(key){
		     case 1:
		     pathname = 'firstcontol'
		     break
		     case 2:
		     pathname = 'Cfbkfirstcontol'
		     break
		     case 3:
		     pathname = 'CfbRfirstcontol'
		     break
		    }
		    const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
		    window.open(newurl.href,'_blank')
		   }
		  },
		         // 双击事件
		    Cclick(num,mark,node,name){
		      // 组装数据
		      clearTimeout(this.grouptime);
		      let spojobj = {
		        num:num,
		        mark:mark,
		        node:node,
		        name:name
		      }
		      this.spotArr.push(spojobj)
		      // 数组去重
		      let deWeightThree = () => {
		          let map = new Map();
		          for (let item of this.spotArr) {
		              if (!map.has(item.name)) {
		                 this.$message.closeAll()
		                  map.set(item.name, item);
		                 this.$message.success('已添加');
		              }else{
		                this.$message.closeAll()
		                this.$message.error('已添加到变量池里，切勿重复添加');
		              }
		          }
		       
		          return [...map.values()];
		      }
		      this.spotArr = deWeightThree();
		      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
		    },
		    //    isshowfase(){
		    //    this.isComShow = false
		    //  },
		    closeCompon(){
		
		      this.$emit('sendStatus', 'Param041',false)
		    },
		    isClose(val,val1){
		      switch(val){
		        case 'Historical':
		          return this.isHshow = val1
				case 'CfbCommonSetting':
							case (this.flag == 5):	
							return  this.flag = 4,this.$bus.$emit("footersendMsg", 4)
							case 'Cfbserve':
							case (this.flag == 7):	
							return  this.flag = 4,this.$bus.$emit("footersendMsg", 4)
		      }
		    },
		    toIpt(data,name,historyname,node){
		
		      this.$refs.inputVal.open(data,name,historyname,node)
		    },
		    toCompon(key,name,name2,name3,titname){
		   this.isIndex = key
		   this.historyname = name
		   this.node = name2
		   this.Lkname = name3
		      clearTimeout(this.grouptime);
		      this.grouptime=setTimeout(() =>{
		          switch(key){
		      case 0:
		          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
		        case 1 :
		          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
		        case 2:
		          return  this.isHshow = true
		        case 4:
		    return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
		   }
		      },300);    
		  },
		    } 
		}
</script>

<style lang="scss" scoped>
	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
		.fl-t{
			float: left;
		}
		.fr-t {
			float: right;
		}
		.zhuangshizb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.5vh;
		}
		.zhuangshizj {
			width: 68vw;
			height: 4vh;
			line-height:4vh;
			margin-top:1vh;
			text-align: center;
			// background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			// margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;	  
		}
		.zhuangshiyb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.5vh;
		}
	}
	.table1{
		width: 95vw;
		height: 51vh;
		border:1px solid rgba(0,228,255,.2);
		box-sizing: border-box;
		font-family: PingFang-SC-Regular;
		font-weight: normal;
		font-stretch: normal;
		margin-top: 2.5vh;
		.title{
			width: 4vw;
			height: 51vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			text-align: center;
			.title_context{
				margin-top: 19.8vh;
				font-size: 2.6vh;
				line-height: 3vh;
				letter-spacing: 0.5vh;
				color: #d5fffe;
			}
		}
		.table1_line1{
			width: 91vw;
			height: 36vh;
			border-bottom:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			.table1_context{
				width: 16vw;
				height: 36vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.title1{
					width: 15.1vw;
					height: 3vh;
					border-bottom:1px solid rgba(0,228,255,.2);
					box-sizing: border-box;
					font-size: 1.9vh;
					line-height: 2.8vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
				.title1_context{
					width: 15.1vw;
					height: 33vh;
					.float1{
						margin-top: 0.7vh;
					}
					.column1{
						width: 10vw;
						font-size: 1vw;
						line-height: 2.6vh;
						letter-spacing: 0.1vh;
						text-align: right;
						color: #6acafb;
					}
					.column2{
						width: 4vw;
						font-size: 1vw;
						line-height: 2.6vh;
						letter-spacing: 0vh;
						color: #d9f8ff;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
		.table1_line2{
			width: 91vw;
			height: 15vh;
			.form1{
				width: 30.5vw;
				height: 15vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.float1{
					margin-top: 6.6vh;
				}
				.line2{
					margin-left: 1vw;
				}
				.float2{
					margin-top: 4vh;
				}
				.column1{
					width: 10vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 4vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.form2{
				width: 30.5vw;
				height: 15vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.form2_word{
					font-size: 1.9vh;
					line-height: 5.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					margin-top: 4vh;
					margin-left: 1vw;
				}
				.float1{
					margin-top: 2vh;
				}
				.column1{
					width: 9vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 4vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.form3{
				width: 30.45vw;
				height: 7.45vh;
				border-right:1px solid rgba(0,228,255,.2);
				border-bottom:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.form3_word{
					font-size: 1.9vh;
					line-height: 5.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					margin-top: 1.5vh;
					margin-left: 1vw;
				}
				.float1{
					margin-top: 1vh;
				}
				.column1{
					width: 9vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 3vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
	.word{
		margin-top: 4vh;
		.location{
			margin-left: 6vw;
			.column1{
				width: 9vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0.1vh;
				text-align: right;
				color: #2fc3e3;
			}
			.column2{
				width: 3.5vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0vh;
				color: #d9f8ff;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.table2{
		width: 95vw;
		height: 17vh;
		border:1px solid rgba(0,228,255,.2);
		box-sizing: border-box;
		margin-top: 0vh;
		.title{
			width: 4vw;
			height: 17vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			text-align: center;
			.title_context{
				margin-top: 2.5vh;
				font-size: 2.6vh;
				line-height: 3vh;
				letter-spacing: 0.5vh;
				color: #d5fffe;
			}
		}
		.table2_line1{
			width: 15.1vw;
			height: 17vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			.table2_line1_context{
				margin-top: 2vh;
			}
			.button{
				margin-left: 1vw;
				width: 3vw;
			}
			.column1{
				width: 7vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0.1vh;
				text-align: right;
				color: #6acafb;
			}
			.column2{
				width: 3vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0vh;
				color: #d9f8ff;
				text-align: center;
				margin-left: 0.3vw;
				cursor: pointer;
			}
		}
		.table2_line2{
			width: 30.45vw; 
			height: 17vh;
			// border-bottom:1px solid rgba(0,228,255,.2);
			// box-sizing: border-box;
			.first{
				border-bottom:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.button{
					margin-left: 3vw;
					width: 3vw;
				}
				.column1{
					width: 14vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 6vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.second{
				.explain_context{
					font-size: 1vw;
					line-height: 2.7vh;
					letter-spacing: 0.1vh;
					color: #6acafb;
					margin-top: 1.8vh;
					margin-left: 3.2vw;
					.blank{
						width: 1.8vw;
					}
				}
			}
		}
	}

  
 ::v-deep{
  .el-switch.is-disabled .el-switch__core{
                cursor: pointer !important;
            }
   .el-switch.is-disabled{
                           opacity: 1 !important;
       }
}
</style>
